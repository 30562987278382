import React, { useState } from "react";
import cookies from "cookies-js";
import { cookiesWork } from "@src/utils/cookies";
import { useEffect } from "react";
import { Flex, Box } from "@src/components/Boxes";
import Logo from "@src/assets/images/configure-app/logo.svg";
import SEO from "@src/components/SEO";
import { Button } from "@src/components/nessie-web";
import Translate from "@src/components/translation/Translate";
import { logEvent } from "@src/utils/logClient";

const ConfigureApp: React.FC = () => {
  const [returnToAppLink, setReturnToAppLink] = useState("https://www.classdojo.com");
  useEffect(() => {
    if (cookiesWork()) {
      const deferredUniversalLink = cookies.get("deferredUniversalLink");
      logEvent({
        eventName: "externalConfigureAppGetCookie",
        eventValue: deferredUniversalLink,
      });
      if (deferredUniversalLink) {
        cookies.expire("deferredUniversalLink");
        const encodedLink = window.btoa(deferredUniversalLink);
        setReturnToAppLink(`classdojo://www.classdojo.com/ul/appRedirect?link=${encodedLink}`);
        window.location.replace(`classdojo://www.classdojo.com/ul/appRedirect?link=${encodedLink}`);
      } else {
        const encodedLink = window.btoa(`https://www.classdojo.com/ul/home`);
        setReturnToAppLink(`classdojo://www.classdojo.com/ul/appRedirect?link=${encodedLink}`);
        window.location.replace(`classdojo://www.classdojo.com/ul/appRedirect?link=${encodedLink}`);
      }
    } else {
      const encodedLink = window.btoa(`https://www.classdojo.com/ul/home`);
      setReturnToAppLink(`classdojo://www.classdojo.com/ul/appRedirect?link=${encodedLink}`);
      window.location.replace(`classdojo://www.classdojo.com/ul/appRedirect?link=${encodedLink}`);
    }
  }, []);

  return (
    <>
      <SEO noindex={true} />
      <Flex justifyContent="center" width="100%" height="calc(100vh - 88px)">
        <Flex width="225px" textAlign="center" alignItems="center" flexDirection="column" marginTop="100px">
          <Box width="100px" minWidth="100px">
            <img src={Logo} alt="" />
          </Box>
          {/* Not yet translated because it is subject to change. */}
          <p>You’re all set, tap open to return to the app</p>
          <Button href={returnToAppLink}>
            <Translate path="configure_app.open_app" />
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default ConfigureApp;
